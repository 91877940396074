import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Col from 'react-bootstrap/Col';
import Section from "../../components/Section"
import Headline from "../../components/Headline"
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button"
import { loadScriptPromise } from "../../services/utility";

export default class WLS2021Hope extends React.Component {
  render() {
    loadScriptPromise("https://www.google.com/recaptcha/api.js?render=6Lfq5fwZAAAAAANqbQ11iS3oLwBTLmbc9vCAaFz2", "recaptcha")
      .then(() => {
        if(typeof window !== 'undefined') {
          if(typeof window.grecaptcha !== 'undefined') {
            window.grecaptcha.ready(function() {
              window.grecaptcha.execute('6Lfq5fwZAAAAAANqbQ11iS3oLwBTLmbc9vCAaFz2', {action: 'homepage'})
              .then(function(token) {
                document.getElementById('captchaResponse').value = token;
              }).catch(() => {});
            });
          }  
        }
      });

    return ( 
      <Layout>
        <Seo title="Hope" />
        <Section className="white" useCol={false}>
          <Col xs={12} md={8}>
            <Headline>You have found Hope!</Headline>

            <div className="pt-2 pb-5">
              We are excited that you prayed to start your relationship with Jesus!  Welcome to the family of God!  
              We would love to send you some free gifts to help you get off to a great start in this relationship.  
              Take a moment to fill out the form below and we'll get this OEW gift packet out to you.
            </div>

            <Form method="POST" action="https://getform.io/f/0841bc7d-5f09-4683-80c5-5e0e5d4e3968">
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control required type="text" placeholder="First Name" name="firstName" />
              </Form.Group>
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control required type="text" placeholder="Last Name" name="lastName" />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control required type="email" placeholder="Email" name="email" />
                <Form.Text>
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control required type="text" placeholder="Address" name="address" />
              </Form.Group>
              <Form.Group controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control required type="text" placeholder="City" name="city" />
              </Form.Group>
              <Form.Group controlId="state">
                <Form.Label>State/Province</Form.Label>
                <Form.Control required type="text" placeholder="State/Province" name="state" />
              </Form.Group>
              <Form.Group controlId="postalCode">
                <Form.Label>Zip/Postal Code</Form.Label>
                <Form.Control required type="text" placeholder="Zip/Postal Code" name="postalCode" />
              </Form.Group>
              <Form.Group controlId="tribe">
                <Form.Label>Tribe</Form.Label>
                <Form.Control type="text" placeholder="Tribe" name="tribe" />
              </Form.Group>
              <Form.Group controlId="message">
                <Form.Label>Prayer Requests</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Prayer Requests" name="message" />
              </Form.Group>
              <input type="hidden" id="captchaResponse" name="g-recaptcha-response" />
              <Button variant="primary" type="submit" className="mt-2 mb-4">
                <b>Submit</b>
              </Button>
            </Form>
          </Col>
        </Section>
      </Layout>
    );
  }
}
